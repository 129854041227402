import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ["plan", "submit", "priceId"]
  static values = {
    plan: Number
  }
  
  connect() {
    if(this.submitTarget != undefined && this.submitTarget != null && this.priceIdTarget != null){
      this.setup()
    }
    else{
      setTimeout(() => {
        this.connect()
      }, 250)
    }
  }
  
  setup(){
    const selectedPlan = this.planTargets.find(plan => plan.classList.contains("selected-plan"))
    if(selectedPlan){
      this.planValue = selectedPlan.value
      this.priceIdTarget.value = selectedPlan.value
      this.submitTarget?.removeAttribute("disabled")
    }
    else{
      this.submitTarget?.setAttribute("disabled", "")
    }
  }
  
  selectPlan(e){
    this.priceValue = e.target.value
    this.priceIdTarget.value = e.target.value
    this.planTargets.forEach(plan => {
      plan.value == e.target.value ? plan.classList.add("selected-plan") : plan.classList.remove("selected-plan");plan.classList.add("opacity-90")
    })
  }

}
