import { Controller } from "stimulus";
import { render, h } from 'preact'
import ListChart from '../components/charts/list'

export default class extends Controller {
  
  static targets = ["chart"]
  
  static values = {
    id: Number,
    type: String,
    didLoad: Boolean,
    isInView: Boolean
  }
  
  connect() {
    if(this.chartTarget){
      this.checkInView()
    }
  }
  
  checkInView() {
    var rect = this.element.getBoundingClientRect();
    var view_height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    this.isInViewValue = !(rect.bottom < 0 || rect.top - view_height >= 0);
    if(this.isInViewValue && ((this.didLoadValue && document.documentElement.hasAttribute("data-turbolinks-preview")) || !this.didLoadValue)){
      this.loadChart()
    }
  }
  
  loadChart(){
    render(<ListChart type={this.typeValue} id={this.idValue} />, this.chartTarget)
    this.didLoadValue = true
  }
  
}