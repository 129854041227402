import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "placeholder", "initials", "remove"]
  
  constructor(props){
    super(props)
    this.reader_load = this.reader_load.bind(this)
  }

  update_placeholder(event) {
    if (event.target.id === "user_avatar" && event.target.files.length > 0) {
      let reader  = new FileReader()
      reader.onload = this.reader_load
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  
  reader_load(e) {
    if(e.target.result.length && e.target.result !== ""){
      this.placeholderTarget.style.backgroundImage = `url(${ e.target.result })`
      this.placeholderTarget.src = `${ e.target.result }`
      this.placeholderTarget.srcset = null
      this.initialsTarget.classList.add("hidden")
      this.removeTarget.classList.remove("hidden")
    }
  }
  
  remove_avatar(){
    this.inputTarget.value = null
    this.placeholderTarget.style.backgroundImage = null
    this.initialsTarget.classList.remove("hidden")
    this.removeTarget.classList.add("hidden")
  }
  
}