// Entry point for the build script in your package.json
require("@rails/ujs").start()
require("trix")
require("@rails/actiontext")
import "@hotwired/turbo-rails"
import './components/trix'
import "./controllers"

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

document.addEventListener("touchstart", function () { }, true);
