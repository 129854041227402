import { Controller } from "stimulus";
import { render, h } from 'preact'
import HomeChart from '../components/charts/home'
import AdChart from '../components/charts/ad'
import CategoryChart from '../components/charts/category'
import ClientChart from '../components/charts/client'
import PortalChart from "../components/charts/portal";

export default class extends Controller {
	static targets = ["stats_chart"]

	connect() {
		if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
			if (this.stats_chartTarget !== null) {
				const type = this.stats_chartTarget.getAttribute('data-type')
				if (type !== null) {
					const data_value = this.stats_chartTarget.getAttribute('data-value')
					switch (type) {
						case "home":
							render(<HomeChart />, this.stats_chartTarget);
							break;
						case "ad":
							render(<AdChart id={data_value} />, this.stats_chartTarget);
							break;
						case "category":
							render(<CategoryChart id={data_value} />, this.stats_chartTarget);
							break;
						case "client":
							render(<ClientChart id={data_value} />, this.stats_chartTarget);
							break;
						case "portal":
							render(<PortalChart id={data_value} />, this.stats_chartTarget);
							break;
					}
				}
			}
		}
	}
}